import React from 'react';


const LanguageSwitcher = ({ selectedLanguage, onLanguageChange }) => {
    const handleLanguageChange = (e) => {
        const newLanguage = e.target.value;
        onLanguageChange(newLanguage);
    };

    return (
        <div className="my-4">
            {/*<label className="mr-2">Select Language:</label>*/}
            <select value={selectedLanguage} onChange={handleLanguageChange} className="rounded-md">
            <option value="en">English</option>
                    <option value="de">Deutsch</option>
                    <option value="fr">Français</option>
                    <option value="it">Italiano</option>
                    <option value="pl">Polski</option>
                    <option value="ua">Українська</option>
                    <option value="es">Español</option>
                    <option value="hun">Magyar</option>
                    <option value="bul">Български</option>
                    <option value="cn">中国语</option>
                {/* Add more language options as needed */}
            </select>
        </div>
    );
};

export default LanguageSwitcher;
