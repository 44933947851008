export default {
    welcomeMessage: 'Imparate le lingue online! Corsi di tedesco',
    aboutUs: 'Su di me',
    contact: 'Contattaci',
    subWelcome: "Testo di benvenuto secondario Dolor lomit",
    ctaWelcome: "All'offerta",
    ctaLearnmore: "Contattatemi",
    introductionHeader: "Chi siamo",
    hello: "Benvenuti su www.online-german.com - il vostro esperto per imparare il tedesco online!",
    hello2: "Perché dovreste scegliere www.online-german.com?",
    subHelloText: "State cercando un insegnante di tedesco qualificato per migliorare le vostre competenze linguistiche in tedesco? O avete bisogno di assistenza nella preparazione per importanti esami di lingua o per colloqui di lavoro imminenti? Su www.online-german.com siete nel posto giusto! I nostri insegnanti di tedesco esperti offrono lezioni su misura per tutti i livelli linguistici, dall'A1.1 al C2.2. Il nostro focus è sull'apprendimento personalizzato del tedesco per assicurare che siano soddisfatti i vostri specifici bisogni e obiettivi. Vi sosteniamo nella vita di tutti i giorni, nell'ambito dell'istruzione e in un contesto professionale. I nostri efficaci metodi didattici mettono l'accento sulle competenze di conversazione, sulla pronuncia corretta, sulla competenza nella scrittura e sulla profonda comprensione della grammatica tedesca. Utilizziamo una vasta gamma di materiali, tra cui libri online o cartacei, presentazioni, video, dialoghi interattivi, testi, schede di lavoro, poesie e canzoni per massimizzare il vostro successo nell'apprendimento.",
    subHelloText2: "Insegnanti di tedesco qualificati ed esperti, orari flessibili delle lezioni, lezioni personalizzate per tutti i livelli linguistici dall'A1 al C2, diverse offerte di corsi per tutte le fasce d'età, metodi didattici efficaci per progressi rapidi, preparazione agli esami per il Certificato Goethe, supporto mirato per i colloqui di lavoro, enfasi sulle competenze di scrittura e sulla grammatica, attenzione alla conversazione focalizzata e alla pronuncia corretta, comodi corsi di lingua online da casa, materiali didattici versatili per massimizzare il vostro successo nell'apprendimento",
    subHelloText3: "Iniziate oggi stesso! Contattateci per saperne di più sui nostri corsi e servizi e iscrivetevi per portare le vostre competenze linguistiche in tedesco al livello successivo. Non vediamo l'ora di accogliervi!",
    angeboteHeader: "Offerte",
    angeboteTitle: "Cosa viene offerto",
    angeboteText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eget egestas",
    allgDeutsch: "Tedesco generale",
    berufDeutsch: "Tedesco per la professione",
    intensivDeutsch: "Corso intensivo di tedesco",
    testDeutsch: "Preparazione al test linguistico 'Goethe Institut'",
    interviewDeutsch: "Preparazione a un colloquio o a un esame",
    boosterDeutsch: "Aggiornamento tedesco",
    konversationDeutsch: "Tedesco conversazionale",
    grammatikDeutsch: "Grammatica in tedesco",
    hahilfeDeutsch: "Aiuto nei compiti e preparazione a dettati o esami scolastici: Tedesco, matematica, inglese, ecc.",
    foerderungDeutsch: "Tutoraggio e supporto per la scuola primaria e secondaria o l'università: anche per studenti con dislessia, discalculia, AD(H)S ecc.",
    
    angeboteInfo: "Su richiesta, è possibile prenotare anche corsi di lingua in inglese o francese.",
    sprachPakete: "Pacchetti di corsi di lingua",
    stundenPakete: "5, 10, 15, 20 o 30 ore",
    beschreibungPakete: "Tutti i corsi di lingua si svolgono online tramite Zoom, Skype, Microsoft Teams o Google Meet. Prezzi su richiesta.",
    kontaktFooterTitle: "Contattaci",
    kontaktFooterText: "Testo Testo Testo Testo",
    kontaktFooterColumnTitle: "Contatto",
    kontaktFooterColumnPhone: "Telefono",

    About: "Chi siamo",
    Courses: "Corsi",
    Contact: "Contattaci",
    callNow: "Chiama ora",
    gruppenPakete: "È possibile prenotare corsi individuali e di gruppo!",

};