import React, { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, BookOpenIcon, AcademicCapIcon, ChatBubbleLeftRightIcon, XMarkIcon, ArrowPathIcon, FingerPrintIcon, PhoneArrowUpRightIcon, ArrowTrendingUpIcon, PaperClipIcon, CalendarDaysIcon, HandRaisedIcon, BriefcaseIcon, CloudArrowUpIcon, LockClosedIcon, ServerIcon, BookmarkIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline'
import pic from './images/pic.jpg';
import LanguagePopup from './components/LanguagePopup';
import deTranslations from './translations/deTranslations';
import itTranslations from './translations/itTranslations';
import frTranslations from './translations/frTranslations';
import plTranslations from './translations/plTranslations';
import enTranslations from './translations/enTranslations';
import esTranslations from './translations/esTranslations';
import uaTranslations from './translations/uaTranslations';
import hunTranslations from './translations/hunTranslations';
import bulTranslations from './translations/bulTranslations';
import cnTranslations from './translations/cnTranslations';
import AdsComponent from './components/AdsComponent';
import {Adsense} from '@ctrl/react-adsense';


import LanguageSwitcher from "./components/LanguageSwitcher";

export default function App() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const storedLanguage = localStorage.getItem('selectedLanguage'); // Retrieve the selected language from localStorage
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage || ''); // Use storedLanguage as initial state



    const translationsMap = {
        de: deTranslations,
        fr: frTranslations,
        it: itTranslations,
        pl: plTranslations,
        en: enTranslations,
        ua: uaTranslations,
        es: esTranslations,
        hun: hunTranslations,
        bul: bulTranslations,
        cn: cnTranslations
    };
    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        localStorage.setItem('selectedLanguage', language); // Save the selected language to localStorage
    };

    const handleLanguageChange = (newLanguage) => {
        setSelectedLanguage(newLanguage);
        localStorage.setItem('selectedLanguage', newLanguage);
    };

    if (!selectedLanguage) {
        return <LanguagePopup onLanguageSelect={handleLanguageSelect} />;
    }

    const selectedTranslations = translationsMap[selectedLanguage];


    const features = [
        {
            name: selectedTranslations.allgDeutsch,
            icon: AcademicCapIcon,
        },
        {
            name: selectedTranslations.berufDeutsch,
            icon: BriefcaseIcon,
        },
        {
            name: selectedTranslations.intensivDeutsch,
            icon: PaperClipIcon,
        },
        {
            name: selectedTranslations.testDeutsch,
            icon: ArrowTrendingUpIcon,
        },
        {
            name: selectedTranslations.interviewDeutsch,
            icon: PhoneArrowUpRightIcon,
        },
        {
            name: selectedTranslations.boosterDeutsch,
            icon: ArrowPathIcon,
        }, {
            name: selectedTranslations.konversationDeutsch,
            icon: ChatBubbleLeftRightIcon,
        }, {
            name: selectedTranslations.grammatikDeutsch,
            icon: BookOpenIcon,
        },
        {
            name: selectedTranslations.hahilfeDeutsch,
            icon: BuildingLibraryIcon,
        },
        {
            name: selectedTranslations.foerderungDeutsch,
            icon: BookmarkIcon,
        }
    ]

    const navigation = [
        { name: selectedTranslations.About, href: '#about' },
        { name: selectedTranslations.Courses, href: '#offers' },
        { name: selectedTranslations.Contact, href: '#contact' },
    ]


    return (
        <div className="bg-white">
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <img
                                className="h-36 max-w-full rounded-full"
                                src={pic}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:gap-x-4 items-center ml-6"> {/* Added spacing with lg:gap-x-4 */}
                        <LanguageSwitcher selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="lg:flex lg:gap-x-4 items-center"> {/* Added spacing with lg:gap-x-4 */}
                                <LanguageSwitcher selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                {/* Landing Section */}

                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div className="text-center mb-6">
                        {/* Add Instagram and Facebook buttons side by side */}
                        <div className="flex items-center justify-center gap-4">
                            <a href="https://www.instagram.com/learn.german.easly/" target="_blank" rel="noopener noreferrer">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                            </a>
                            <a href="https://www.facebook.com/Germanonline2023/" target="_blank" rel="noopener noreferrer">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                                </svg>
                            </a>
                        </div>
                    </div>

                    {/* Phone number box */}
                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 text-center">
                        {selectedTranslations.kontaktFooterColumnPhone}: +49 17630427747 | Email: anette1075@yahoo.de{' '}
                        <a href="tel:+4917630427747" className="font-semibold text-purple-700">
                            <span className="absolute inset-0" aria-hidden="true" />
                            {selectedTranslations.callNow} <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>

                    <div className="text-center mt-6">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            {selectedTranslations.welcomeMessage}
                        </h1><br></br>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {/*{selectedTranslations.subWelcome} */}
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="#offers"
                                className="rounded-md bg-purple-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-700"
                            >
                                {selectedTranslations.ctaWelcome}
                            </a>
                            <a href="#contact" className="text-sm font-semibold leading-6 text-gray-900">
                                {selectedTranslations.ctaLearnmore}
                                <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>


                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
            <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                <div className="absolute inset-0 -z-10 overflow-hidden">

                    <svg
                        className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M100 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                    </svg>

                </div>
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10" id="about">
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="lg:max-w-lg ">
                                <p className="text-base font-semibold leading-7 text-purple-700">{selectedTranslations.introductionHeader}</p>
                                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{selectedTranslations.hello}
                                    👋
                                </h1>
                                <p className="mt-6 text-xl leading-8 text-gray-700 text-justify">
                                    {selectedTranslations.subHelloText}
                                </p>
                            </div><br></br>
                            <div className="lg:max-w-lg ">
                                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{selectedTranslations.hello2}
                                    
                                </h1>
                                <p className="mt-6 text-xl leading-8 text-gray-700 text-justify">
                                    {selectedTranslations.subHelloText2}
                                </p><br></br>
                                <p className="mt-5 text-xl leading-8 text-gray-700 text-justify">
                                {selectedTranslations.subHelloText3}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Angebote */}

            <div className="bg-white py-24 sm:py-32" id="offers">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-base font-semibold leading-7 text-purple-700">{selectedTranslations.angeboteHeader}</h2>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {selectedTranslations.angeboteTitle}
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {/*{selectedTranslations.angeboteText}*/}
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-16">
                                    <dt className="text-xl font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-700">
                                            <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </div>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-xl leading-7 text-gray-600">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                    <div className="text-center py-4 mt-16 lg:px-4">
                        <div
                            className="p-2 bg-purple-800 items-center text-purple-100 leading-none lg:rounded-full flex lg:inline-flex"
                            role="alert">
                            <span className="flex rounded-full bg-purple-500 uppercase px-2 py-1 text-xs font-bold mr-3">Info</span>
                            <span className="font-semibold text-lg mr-2 text-center flex-auto">{selectedTranslations.angeboteInfo}</span>
                        </div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>

            {/* Pakete */}

            <div className="flex-wrap items-center justify-center gap-8 text-center sm:flex">
                <div className="w-full px-4 py-4 mt-6 bg-gray-800 rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 sm:mt-16 md:mt-20 lg:mt-24 dark:bg-gray-800">
                    <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 mx-auto text-white bg-purple-700 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                            </svg>
                        </div>
                    </div>
                    <h3 className="py-4 text-3xl font-semibold text-white">
                        {selectedTranslations.sprachPakete}
                    </h3>
                    <h2 className="py-1 text-2xl font-bold text-gray-300">
                        {selectedTranslations.stundenPakete}
                    </h2>
                    <h2 className="py-1 text-2xl font-semibold text-gray-300">
                        {selectedTranslations.gruppenPakete}
                    </h2>
                    <p className="py-4 text-gray-300 text-xl">
                        {selectedTranslations.beschreibungPakete}
                    </p>
                </div>
            </div><br /><br /><br /><br /><br /><br />

            {/* Footer */}

            <div className="relative isolate overflow-hidden bg-white py-16 sm:py-24 lg:py-32 text-xl" id="contact">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
      <div className="max-w-xl lg:max-w-lg">
        <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">{selectedTranslations.kontaktFooterTitle}</h2>
        <p className="mt-4 text-lg leading-8 text-gray-400">
          {/*{selectedTranslations.kontaktFooterText}*/}
        </p>
      </div>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
        <div className="flex flex-col items-start">
          <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
            <CalendarDaysIcon className="h-6 w-6 text-black" aria-hidden="true" />
          </div>
          <dt className="mt-4 font-semibold text-black">{selectedTranslations.kontaktFooterColumnTitle}</dt>
          <dd className="mt-2 leading-7 text-gray-400">
            <p className="mr-1 text-gray-800">Aneta Oczkowska</p>
          </dd>
          <dd className="mt-2 leading-7 text-gray-400">
            <p className="mr-1 text-gray-800">{selectedTranslations.kontaktFooterColumnPhone}/WhatsApp</p>
            <a
              href="tel:+4917630427747"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              +49 17630427747
            </a>
          </dd>
          <dd className="mt-2 leading-7 text-gray-400 mb-9">
            <p className="mr-1 text-gray-800">Email</p>
            <a
              href="mailto:anette1075@yahoo.de"
              aria-label="Our phone"
              title="Our phone"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              anette1075@yahoo.de
            </a>
          </dd>
        </div>
        <div className="flex flex-col items-start">
        </div>
      </dl>
      <p className="text-xs leading-5 text-gray-400 whitespace-pre-line">
  #learn languages&nbsp;
  #learn languages online&nbsp;
  #learn languages&nbsp;
  #learn languages with me&nbsp;
  #learn german online&nbsp;
  #language online&nbsp;
  #language learning websites&nbsp;
  #languages online&nbsp;
  #learn german&nbsp;
  #learn german language online&nbsp;
  #study german online&nbsp;
  #teach language online&nbsp;
  #german learning websites&nbsp;
  #german language&nbsp;
  #language teaching&nbsp;
  #german language online&nbsp;
  #teaching german online&nbsp;
  #german language study&nbsp;
  #study languages online&nbsp;
  #for learning&nbsp;
  #to learn languages&nbsp;
  #german language learning&nbsp;
  #study german language online&nbsp;
  #german language learn&nbsp;
  #online language easly
  DE&nbsp;
  #Deutsch lernen online&nbsp;
  #Onlinedeutschkurse&nbsp;
  #Deutsch als Fremdsprache DaF&nbsp;
  #Deutsch als Zweitsprache DaZ&nbsp;
  #Sprachlernplattform&nbsp;
  #Deutsch lernen für Anfänger und Fortgeschrittene&nbsp;
  #Deutschkurs online&nbsp;
  #deutsche Vokabeln und Grammatik&nbsp;
  #Interaktive Deutschübungen&nbsp;
  #Deutschkurs für Beginner und Fortgeschrittene&nbsp;
  #Deutschprüfungsvorbereitung&nbsp;
  #Online-Sprachunterricht&nbsp;
  #Deutschunterricht&nbsp;
  #Deutsch lernen&nbsp;
  #Kinder&nbsp;
  #Jugendliche&nbsp;
  #Erwachsene&nbsp;
  #Goethe-Zertifikat&nbsp;
  #Großes Deutsches Sprachdiplom&nbsp;
  #Goethe-Institut&nbsp;
  #Magisterpädagogin&nbsp;
  #Sprachschulen&nbsp;
  #Internationale Schule&nbsp;
  #Europäische Schule&nbsp;
  #Bildung&nbsp;
  #Beruf&nbsp;
  #Anfängerniveau&nbsp;
  #Fortgeschrittenes Level&nbsp;
  #Job-Interview&nbsp;
  #Prüfungsvorbereitung&nbsp;
  #Konversationsfähigkeiten&nbsp;
  #Korrekte Aussprache&nbsp;
  #Schreibkompetenz&nbsp;
</p>
                    </div>
                    <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t border-black lg:flex-row mt-6">
                        <p className="text-sm text-gray-600">
                            © Copyright 2023 Julian Hoffmann. All rights reserved.
                        </p>
                        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                            <li>
                                <a
                                    href="/legal.html"
                                    className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                                >
                                    Legal Notice
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/privacy.html"
                                    className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
