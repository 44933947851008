export default {
    welcomeMessage: 'Вивчайте мови онлайн! Курси німецької мови',
    aboutUs: 'Про нас',
    contact: 'Контакт',
    subWelcome: "Додатковий текст вітання Dolor lomit",
    ctaWelcome: "До пропозиції",
    ctaLearnmore: "Зв'яжіться зі мною",
    introductionHeader: "Вступ",
    hello: "Привіт, мене звуть Анетт",
    subHelloText: "Я з Німеччини і викладаю німецьку мову для дітей, підлітків та дорослих. З 2012 року я маю сертифікат Goethe C2.2, Великий Німецький мовний диплом Інституту Гете, я є магістром-педагогом і викладаю німецьку мову з 2011 року в різних мовних школах, включаючи Міжнародну та Європейську школу в Люксембурзі. Мій основний напрямок навчання - це німецька для школи, вищої освіти та професії. Мій урок охоплює рівні від початкового A1.1 до високого C2.2. Я також можу добре підготувати вас до співбесіди на роботу або до мовного тесту Інституту Гете. Під час наших уроків ми надаємо основну увагу конверсації, правильному вимовленню, письму та граматиці німецької мови. Матеріали, які ми використовуємо на уроках, включають онлайн або підручники, презентації, короткі відео, діалоги, робочі листи, пісні та малюнки, які ми разом описуємо. Мені подобається викладати, оскільки спостерігати за успіхами моїх учнів приносить мені велике задоволення. Запишіться на пробний урок та вивчайте німецьку разом зі мною! І, сподіваюся, до зустрічі!",
    angeboteHeader: "Пропозиції",
    angeboteTitle: "Що пропонується",
    angeboteText: "Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas",

    allgDeutsch: "Загальний німецький",
    berufDeutsch: "Німецька для професії",
    intensivDeutsch: "Інтенсивний курс німецької мови",
    testDeutsch: "Підготовка до мовного тесту 'Goethe Institut'",
    interviewDeutsch: "Підготовка до співбесіди або екзамену",
    boosterDeutsch: "Поновлення німецької мови",
    konversationDeutsch: "Конверсаційний німецький",
    grammatikDeutsch: "Граматика німецької мови",
    hahilfeDeutsch: "Допомога з домашніми завданнями та підготовка до диктантів або шкільних іспитів: німецька, математика, англійська мова тощо.",
    foerderungDeutsch: "Допомога та підтримка для початкової та середньої школи або вищої освіти: також для учнів із дислексією, дискалькулією, AD(H)S тощо.",

    angeboteInfo: "За запитом можна також замовити курси англійської або французької мови.",
    sprachPakete: "Пакети мовних курсів",
    stundenPakete: "5, 10, 15, 20 або 30 годин",
    beschreibungPakete: "Усі мовні курси проводяться онлайн за допомогою Zoom, Skype, Microsoft Teams або Google Meet. Ціни за запитом.",
    kontaktFooterTitle: "Зв'яжіться зі мною",
    kontaktFooterText: "Текст Текст Текст Текст",
    kontaktFooterColumnTitle: "Контакт",
    kontaktFooterColumnPhone: "Телефон",

    About: "Про мене",
    Courses: "Курси",
    Contact: "Контакт",
    callNow: "Зателефонуйте зараз",
    gruppenPakete: "Ви можете забронювати індивідуальні та групові курси!",
};
