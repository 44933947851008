export default {
    welcomeMessage: 'Sprachen online lernen! Deutschkurse',
    aboutUs: 'About Us',
    contact: 'Contact',
    subWelcome: "Sub Wilkommens Text Dolor lomit ",
    ctaWelcome: "Zum Angebot",
    ctaLearnmore: "Kontaktieren Sie uns",
    introductionHeader: "Über uns",
    hello: "Willkommen bei www.online-german.com - Ihrem Experten für Deutschunterricht und Deutschlernen online!",
    hello2: "Warum sollten Sie www.online-german.com wählen?",
    subHelloText: "Suchen Sie einen qualifizierten Deutschlehrer, um Ihre Deutschkenntnisse zu verbessern? Oder benötigen Sie Unterstützung bei der Vorbereitung auf wichtige Sprachprüfungen oder bevorstehende Job-Interviews? Bei www.online-german.com sind Sie an der richtigen Adresse! Unsere erfahrenen Deutschlehrer bieten maßgeschneiderten Unterricht für alle Sprachniveaus von A1.1 bis C2.2. Unser Fokus liegt auf individuellem Deutschlernen, um sicherzustellen, dass Ihre spezifischen Bedürfnisse und Ziele erfüllt werden. Wir unterstützen sowohl im Alltag, im Bildungsbereich als auch im beruflichen Kontext. Unsere effektiven Lehrmethoden betonen Konversationsfähigkeiten, korrekte Aussprache, Schreibkompetenz und tiefes Verständnis der deutschen Grammatik. Wir nutzen eine breite Palette von Materialien, darunter Online- oder Schulbücher, Präsentationen, Videos, interaktive Dialoge, Texte, Arbeitsblätter, Gedichte und Lieder, um Ihren Lernerfolg zu maximieren.",
    subHelloText2: "Qualifizierte und erfahrene Deutschlehrer, flexible Unterrichtszeiten, maßgeschneiderter Unterricht für alle Sprachlevels von A1 - C2, vielfältige Kursangebote für alle Altersgruppen, effektive Lehrmethoden für schnellen Fortschritt, Prüfungsvorbereitung für das Goethe-Zertifikat, gezielte Unterstützung für Job-Interviews, Schwerpunkt auf Schreibfertigkeiten und Grammatik, Betonung auf fokussierte Konversation und korrekte Aussprache, komfortable Online-Sprachkurse von zu Hause aus, vielseitige Lehrmaterialien für Ihren maximalen Lernerfolg",
    subHelloText3: "Beginnen Sie noch heute! Kontaktieren Sie uns, um mehr über unsere Kurse und Dienstleistungen zu erfahren und melden Sie sich an, um Ihre Deutschkenntnisse auf das nächste Level zu bringen. Wir freuen uns auf Sie!",
    angeboteHeader: "Angebote",
    angeboteTitle: "Was wird angeboten?",
    angeboteText: "Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas",

    allgDeutsch: "Allgemeines Deutsch",
    berufDeutsch: "Deutsch für den Beruf",
    intensivDeutsch: "Crash-Intensivdeutschkurs",
    testDeutsch: "Vorbereitung zum \"Goethe-Institut\"-Sprachtest",
    interviewDeutsch: "Vorbereitung zu einem Interview oder einer Prüfung",
    boosterDeutsch: "Booster-Erfrischungsdeutsch",
    konversationDeutsch: "Konversationsdeutsch",
    grammatikDeutsch: "Grammatik in Deutsch",
    hahilfeDeutsch: "Hausaufgabenhilfe und Vorbereitung zu Diktaten oder Schulprüfungen: Deutsch, Mathematik, Englisch etc.",
    foerderungDeutsch: "Nachhilfe und Förderung für die Grund- und Sekundarschule oder das Studium: auch für Schüler mit LRS, Legasthenie, Dyskalkulie, AD(H)S etc.",

    angeboteInfo: "Auf Anfrage können auch Sprachkurse in Englisch oder Französisch gebucht werden.",
    sprachPakete: "Sprachkurspakete",
    stundenPakete: "5, 10, 15, 20 oder 30 Stunden",
    gruppenPakete: "Individuelle- und Gruppenkurse buchbar!",
    beschreibungPakete: "Alle Sprachkurse finden online via Zoom, Skype, Microsoft Teams oder Google Meet statt. Preise auf Anfrage.",
    kontaktFooterTitle: "Nehmen Sie Kontakt mit mir auf",
    kontaktFooterText: "Text Text Text Text",
    kontaktFooterColumnTitle: "Kontakt",
    kontaktFooterColumnPhone: "Telefon",

    About: "Über uns",
    Courses: "Kurse",
    Contact: "Kontakt",
    callNow: "Jetzt anrufen"

};