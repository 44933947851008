export default {
    welcomeMessage: 'Apprendre les langues en ligne ! Cours d\'allemand',
    aboutUs: 'À propos de nous',
    contact: 'Contact',
    subWelcome: "Texte de sous-bienvenue Dolor lomit ",
    ctaWelcome: "Vers l'offre",
    ctaLearnmore: "Contactez-moi",
    introductionHeader: "À propos de nous",
    hello: "Bienvenue sur www.online-german.com - votre expert pour apprendre l'allemand en ligne !",
    hello2: "Pourquoi devriez-vous choisir www.online-german.com ?",
    subHelloText: "Vous recherchez un professeur d'allemand qualifié pour améliorer vos compétences linguistiques en allemand ? Ou avez-vous besoin d'aide pour vous préparer à des examens de langue importants ou à des entretiens d'embauche à venir ? Chez www.online-german.com, vous êtes au bon endroit ! Nos professeurs d'allemand expérimentés proposent des cours sur mesure pour tous les niveaux de langue, de A1.1 à C2.2. Notre accent est mis sur l'apprentissage individualisé de l'allemand pour s'assurer que vos besoins et objectifs spécifiques sont satisfaits. Nous vous soutenons dans la vie quotidienne, dans l'éducation et dans un contexte professionnel. Nos méthodes d'enseignement efficaces mettent l'accent sur les compétences en conversation, la prononciation correcte, la maîtrise de l'écriture et une compréhension approfondie de la grammaire allemande. Nous utilisons une large gamme de supports, y compris des livres en ligne ou scolaires, des présentations, des vidéos, des dialogues interactifs, des textes, des fiches de travail, des poèmes et des chansons pour maximiser votre succès d'apprentissage.",
    subHelloText2: "Professeurs d'allemand qualifiés et expérimentés, horaires de cours flexibles, cours sur mesure pour tous les niveaux de langue de A1 à C2, offres de cours diversifiées pour tous les groupes d'âge, méthodes d'enseignement efficaces pour des progrès rapides, préparation aux examens pour le Certificat Goethe, soutien ciblé pour les entretiens d'embauche, mise en avant des compétences en écriture et de la grammaire, accent sur la conversation ciblée et la prononciation correcte, cours de langue en ligne pratiques depuis chez vous, supports d'enseignement polyvalents pour maximiser votre succès d'apprentissage",
    subHelloText3: "Commencez dès aujourd'hui ! Contactez-nous pour en savoir plus sur nos cours et services, et inscrivez-vous pour perfectionner vos compétences en allemand. Nous avons hâte de vous accueillir !",
    angeboteHeader: "Offres",
    angeboteTitle: "Ce qui est proposé",
    angeboteText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eget egestas",
    allgDeutsch: "Allemand général",
    berufDeutsch: "Allemand professionnel",
    intensivDeutsch: "Cours intensif d'allemand",
    testDeutsch: "Préparation au test de langue 'Goethe Institut'",
    interviewDeutsch: "Préparation à un entretien ou à un examen",
    boosterDeutsch: "Rafraîchissement d'allemand",
    konversationDeutsch: "Allemand conversationnel",
    grammatikDeutsch: "Grammaire en allemand",
    hahilfeDeutsch: "Aide aux devoirs et préparation aux dictées ou aux examens scolaires : Allemand, mathématiques, anglais, etc.",
    foerderungDeutsch: "Tutorat et soutien pour l'école primaire et secondaire ou l'université : également pour les étudiants souffrant de dyslexie, de dyscalculie, d'AD(H)S, etc.",

    
    angeboteInfo: "Sur demande, des cours de langues en anglais ou en français peuvent également être réservés.",
    sprachPakete: "Forfaits de cours de langue",
    stundenPakete: "5, 10, 15, 20 ou 30 heures",
    beschreibungPakete: "Tous les cours de langue se déroulent en ligne via Zoom, Skype, Microsoft Teams ou Google Meet. Prix sur demande.",
    kontaktFooterTitle: "Prenez contact",
    kontaktFooterText: "Texte Texte Texte Texte",
    kontaktFooterColumnTitle: "Contact",
    kontaktFooterColumnPhone: "Téléphone",

    About: "À propos",
    Courses: "Cours",
    Contact: "Contact",
    callNow: "Appeler",
    gruppenPakete: "Possibilité de réserver des cours individuels ou en groupe !",


};