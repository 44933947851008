export default {
    welcomeMessage: 'Online nyelvtanulás! Német tanfolyamok',
    aboutUs: 'Rólunk',
    contact: 'Kapcsolat',
    subWelcome: "Alüdvözlő szöveg Dolor lomit",
    ctaWelcome: "Ajánlat megtekintése",
    ctaLearnmore: "Lépjen kapcsolatba velem",
    introductionHeader: "Bemutatkozás",
    hello: "Helló, nevem Anette",
    subHelloText: "Németországból származom és németet tanítok gyerekeknek, tinédzsereknek és felnőtteknek. 2012 óta rendelkezem Goethe C2.2 tanúsítvánnyal, a Goethe Intézet Nagy Német Nyelvi Diplomájával. Pedagógus vagyok, és 2011 óta tanítom a német nyelvet különböző nyelviskolákban, beleértve a luxemburgi Nemzetközi vagy Európai Iskolát is. A tanítási fókuszom az iskolai, tanulmányi és szakmai német nyelv oktatására irányul. Az óráim kezdő szinttől (A1.1) a haladó szintig (C2.2) terjednek. Nagyon jól fel tudlak készíteni egy állásinterjúra vagy a Goethe Intézet nyelvvizsgájára is. A német nyelvóráimon hangsúlyt fektetünk a beszélgetésre, a helyes kiejtésre, az írásra és a német nyelvtanra. Az órákon használt anyagok magukban foglalják az online vagy nyomtatott tankönyveket, prezentációkat, rövid videókat, párbeszédeket, munkafüzeteket, dalokat és képeket, amelyeket közösen leírunk. Imádok tanítani, mert nagy öröm számomra látni a tanulóim fejlődését. Foglalj egy próbaórát és tanulj németül velem! Remélem, hamarosan találkozunk!",
    angeboteHeader: "Ajánlatok",
    angeboteTitle: "Mi van kínálatban?",
    angeboteText: "Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas",

    allgDeutsch: "Általános német",
    berufDeutsch: "Német szaknyelv",
    intensivDeutsch: "Intenzív német tanfolyam",
    testDeutsch: "Felkészülés a 'Goethe Intézet' nyelvvizsgájára",
    interviewDeutsch: "Felkészülés interjúra vagy vizsgára",
    boosterDeutsch: "Német nyelvtanítás frissítése",
    konversationDeutsch: "Német beszélgetés",
    grammatikDeutsch: "Német nyelvtan",
    hahilfeDeutsch: "Házi feladat segítség és felkészülés írásbeli dolgozatokra vagy iskolai vizsgákra: német, matematika, angol stb.",
    foerderungDeutsch: "Különórák és támogatás általános és középiskolás diákoknak, valamint egyetemi hallgatóknak: ideértve a diszlexiával, diszkalkuliával, ADHD-val stb. rendelkező diákokat is.",

    angeboteInfo: "Kérésre angol vagy francia nyelvtanfolyamokat is foglalhat.",
    sprachPakete: "Nyelvtanfolyamok csomagokban",
    stundenPakete: "5, 10, 15, 20 vagy 30 óra",
    beschreibungPakete: "Az összes nyelvtanfolyam online zajlik Zoom, Skype, Microsoft Teams vagy Google Meet segítségével. Árak kérésre.",
    kontaktFooterTitle: "Lépjen kapcsolatba",
    kontaktFooterText: "Szöveg Szöveg Szöveg Szöveg",
    kontaktFooterColumnTitle: "Kapcsolat",
    kontaktFooterColumnPhone: "Telefon",

    About: "Rólam",
    Courses: "Tanfolyamok",
    Contact: "Kapcsolat",
    callNow: "Hívjon most",
    gruppenPakete: "Egyéni és csoportos tanfolyamok foglalhatók!",
};
