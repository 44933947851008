export default {
    welcomeMessage: '¡Aprende idiomas en línea! Cursos de alemán',
    aboutUs: 'Acerca de nosotros',
    contact: 'Contacto',
    subWelcome: "Texto de bienvenida secundario Dolor lomit",
    ctaWelcome: "Ver oferta",
    ctaLearnmore: "Contáctame",
    introductionHeader: "Introducción",
    introductionHeader: "Acerca de nosotros",
    hello: "¡Bienvenido a www.online-german.com - tu experto en aprender alemán en línea!",
    hello2: "¿Por qué deberías elegir www.online-german.com?",
    subHelloText: "¿Estás buscando un profesor de alemán calificado para mejorar tus habilidades en el idioma alemán? ¿O necesitas ayuda para prepararte para exámenes de idiomas importantes o entrevistas de trabajo próximas? En www.online-german.com, estás en el lugar correcto. Nuestros experimentados profesores de alemán ofrecen lecciones personalizadas para todos los niveles de idioma, desde A1.1 hasta C2.2. Nos enfocamos en el aprendizaje individualizado del alemán para asegurar que se cumplan tus necesidades y objetivos específicos. Te brindamos apoyo en la vida cotidiana, en la educación y en el ámbito profesional. Nuestros efectivos métodos de enseñanza hacen hincapié en las habilidades de conversación, la pronunciación correcta, la competencia en escritura y una profunda comprensión de la gramática alemana. Utilizamos una amplia gama de materiales, que incluyen libros en línea o impresos, presentaciones, videos, diálogos interactivos, textos, hojas de ejercicios, poemas y canciones para maximizar tu éxito en el aprendizaje.",
    subHelloText2: "Profesores de alemán calificados y experimentados, horarios de lecciones flexibles, lecciones personalizadas para todos los niveles de idioma desde A1 hasta C2, diversas ofertas de cursos para todas las edades, métodos de enseñanza efectivos para un progreso rápido, preparación para el Certificado Goethe, apoyo específico para entrevistas de trabajo, énfasis en las habilidades de escritura y gramática, atención a la conversación enfocada y la pronunciación correcta, cómodos cursos de idiomas en línea desde casa, materiales de enseñanza versátiles para maximizar tu éxito en el aprendizaje",
    subHelloText3: "¡Comienza hoy mismo! Contáctanos para obtener más información sobre nuestros cursos y servicios, y regístrate para llevar tus habilidades en alemán al siguiente nivel. ¡Esperamos recibirte!",
    angeboteHeader: "Ofertas",
    angeboteTitle: "¿Qué se ofrece?",
    angeboteText: "Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas",

    allgDeutsch: "Alemán general",
    berufDeutsch: "Alemán para la profesión",
    intensivDeutsch: "Curso intensivo de alemán",
    testDeutsch: "Preparación para el examen de idioma 'Goethe-Institut'",
    interviewDeutsch: "Preparación para una entrevista o un examen",
    boosterDeutsch: "Actualización de alemán",
    konversationDeutsch: "Alemán conversacional",
    grammatikDeutsch: "Gramática en alemán",
    hahilfeDeutsch: "Ayuda con las tareas y preparación para dictados o exámenes escolares: alemán, matemáticas, inglés, etc.",
    foerderungDeutsch: "Clases de apoyo y refuerzo para la escuela primaria y secundaria o para la universidad: también para estudiantes con dislexia, discalculia, TDAH, etc.",

    angeboteInfo: "A pedido, también se pueden reservar cursos de idiomas en inglés o francés.",
    sprachPakete: "Paquetes de cursos de idiomas",
    stundenPakete: "5, 10, 15, 20 o 30 horas",
    beschreibungPakete: "Todos los cursos de idiomas se realizan en línea a través de Zoom, Skype, Microsoft Teams o Google Meet. Precios bajo consulta.",
    kontaktFooterTitle: "Ponte en contacto",
    kontaktFooterText: "Texto Texto Texto Texto",
    kontaktFooterColumnTitle: "Contacto",
    kontaktFooterColumnPhone: "Teléfono",

    About: "Sobre mí",
    Courses: "Cursos",
    Contact: "Contacto",
    callNow: "Llame ahora",
    gruppenPakete: "Se pueden reservar cursos individuales y para grupos.",
};
