export default {
    welcomeMessage: 'Научете езици онлайн! Курсове по немски език',
    aboutUs: 'За нас',
    contact: 'Контакт',
    subWelcome: "Допълнителен текст за добре дошли Dolor lomit",
    ctaWelcome: "Вижте предложението",
    ctaLearnmore: "Свържете се с мен",
    introductionHeader: "Въведение",
    hello: "Здравейте, на име съм Анет",
    subHelloText: "Аз съм от Германия и преподавам немски език на деца, младежи и възрастни. От 2012 г. притежавам сертификат Goethe C2.2, Голям немски езиков диплом на Института Гьоте, съм магистър по педагогика и преподавам немски език от 2011 г. в различни езикови училища, включително Международното или Европейското училище в Люксембург. Моят учебен акцент е върху немския език за училище, учеба и професия. Моите уроци варират от начално ниво A1.1 до напреднало ниво C2.2. Мога също да ви подготвя много добре за работно интервю или езиков изпит на Института Гьоте. По време на нашите уроци по немски език, акцентираме върху разговор, добра произношение, писане и граматика на немския. Материалите, които използваме на уроците, включват онлайн или учебници, презентации, кратки видеа, диалози, работни листове, песни и изображения, които описваме заедно. Обичам да преподавам, защото ме радва да наблюдавам напредъка на моите ученици. Резервирайте пробен урок и научете немски език с мен! Надявам се скоро да се видим!",
    angeboteHeader: "Предложения",
    angeboteTitle: "Какво се предлага?",
    angeboteText: "Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas",

    allgDeutsch: "Общ немски",
    berufDeutsch: "Немски за професията",
    intensivDeutsch: "Интензивен курс по немски език",
    testDeutsch: "Подготовка за езиковия изпит 'Goethe-Institut'",
    interviewDeutsch: "Подготовка за интервю или изпит",
    boosterDeutsch: "Освежаващ курс по немски език",
    konversationDeutsch: "Разговорен немски език",
    grammatikDeutsch: "Граматика на немски език",
    hahilfeDeutsch: "Помощ с домашните задачи и подготовка за диктати или училищни изпити: немски, математика, английски и др.",
    foerderungDeutsch: "Допълнителни уроци и подкрепа за начално и средно училище или университет: също и за ученици с дислексия, дискалкулия, АД (Х)С и др.",

    angeboteInfo: "По заявка могат да се резервират и курсове по английски или френски.",
    sprachPakete: "Пакети езикови курсове",
    stundenPakete: "5, 10, 15, 20 или 30 часа",
    beschreibungPakete: "Всички езикови курсове се провеждат онлайн чрез Zoom, Skype, Microsoft Teams или Google Meet. Цени по заявка.",
    kontaktFooterTitle: "Свържете се с мен",
    kontaktFooterText: "Текст Текст Текст Текст",
    kontaktFooterColumnTitle: "Контакт",
    kontaktFooterColumnPhone: "Телефон",

    About: "За мен",
    Courses: "Курсове",
    Contact: "Контакт",
    callNow: "Обадете се сега",
    gruppenPakete: "Можете да резервирате индивидуални и групови курсове!",

};
