export default {
    welcomeMessage: 'Learn languages online! German courses',
    aboutUs: 'About me',
    contact: 'Contact',
    subWelcome: "Sub Welcome Text Dolor lomit ",
    ctaWelcome: "Offers",
    ctaLearnmore: "Contact me",
    introductionHeader: "About Us",
    hello: "Welcome to www.online-german.com - your expert for learning German online!",
    hello2: "Why should you choose www.online-german.com?",
    subHelloText: "Are you looking for a qualified German teacher to improve your German language skills? Or do you need assistance in preparing for important language exams or upcoming job interviews? At www.online-german.com, you're at the right place! Our experienced German teachers provide tailored lessons for all language levels from A1.1 to C2.2. Our focus is on individualized German learning to ensure your specific needs and goals are met. We support you in everyday life, in education, and in a professional context. Our effective teaching methods emphasize conversation skills, correct pronunciation, writing proficiency, and a deep understanding of German grammar. We use a wide range of materials, including online or school books, presentations, videos, interactive dialogues, texts, worksheets, poems, and songs to maximize your learning success.",
    subHelloText2: "Qualified and experienced German teachers, flexible lesson times, customized lessons for all language levels from A1 - C2, diverse course offerings for all age groups, effective teaching methods for rapid progress, exam preparation for the Goethe Certificate, targeted support for job interviews, a focus on writing skills and grammar, emphasis on focused conversation and correct pronunciation, convenient online language courses from home, versatile teaching materials for your maximum learning success",
    subHelloText3: "Start today! Contact us to learn more about our courses and services and sign up to take your German language skills to the next level. We look forward to having you!",
    angeboteHeader: "Offers",
    angeboteTitle: "What is offered?",
    angeboteText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eget egestas",
    allgDeutsch: "General German",
    berufDeutsch: "German for the profession",
    intensivDeutsch: "Crash intensive German course",
    testDeutsch: "Preparation for the 'Goethe Institute' language test",
    interviewDeutsch: "Preparation for an interview or exam",
    boosterDeutsch: "Booster refresh German",
    konversationDeutsch: "Conversational German",
    grammatikDeutsch: "Grammar in German",
    hahilfeDeutsch: "Homework help and preparation for dictations or school exams: German, mathematics, English etc.",
    foerderungDeutsch: "Tutoring and support for primary and secondary school or university: also for student with LRS, dyslexia, dyscalculia, AD(H)S etc.",

    
    angeboteInfo: "Upon request, language courses in English or French can also be booked.",
    sprachPakete: "Language course packages",
    stundenPakete: "5, 10, 15, 20, or 30 hours",
    beschreibungPakete: "All language courses take place online via Zoom, Skype, Microsoft Teams, or Google Meet. Prices upon request.",
    kontaktFooterTitle: "Get in touch",
    kontaktFooterText: "Text Text Text Text",
    kontaktFooterColumnTitle: "Contact",
    kontaktFooterColumnPhone: "Phone",

    About: "About",
    Courses: "Courses",
    Contact: "Contact",

    callNow: "Call now",
    gruppenPakete: "Individual and group courses bookable!",

};