export default {
    welcomeMessage: 'Ucz się języków online! Kursy niemieckiego',
    aboutUs: 'O mnie',
    contact: 'Kontakt',
    subWelcome: "Tekst podpowitalny Dolor lomit",
    ctaWelcome: "Do oferty",
    ctaLearnmore: "Kontakt ze mną",
    introductionHeader: "O nas",
    hello: "Witaj na www.online-german.com - twoim ekspertem do nauki niemieckiego online!",
    hello2: "Dlaczego powinieneś wybrać www.online-german.com?",
    subHelloText: "Szukasz kwalifikowanego nauczyciela niemieckiego, aby poprawić swoje umiejętności językowe? A może potrzebujesz pomocy w przygotowaniach do ważnych egzaminów językowych lub nadchodzących rozmów kwalifikacyjnych? W www.online-german.com jesteś we właściwym miejscu! Nasi doświadczeni nauczyciele niemieckiego oferują spersonalizowane lekcje na wszystkich poziomach języka od A1.1 do C2.2. Nasz nacisk kładzie się na indywidualne nauczanie niemieckiego, aby zapewnić spełnienie twoich konkretnych potrzeb i celów. Wspieramy cię w codziennym życiu, w edukacji oraz w kontekście zawodowym. Nasze skuteczne metody nauczania podkreślają umiejętności konwersacyjne, poprawną wymowę, umiejętność pisania oraz głębokie zrozumienie niemieckiej gramatyki. Wykorzystujemy szeroki zakres materiałów, w tym książki online lub szkolne, prezentacje, filmy, interaktywne dialogi, teksty, arkusze pracy, wiersze i piosenki, aby maksymalizować twój sukces w nauce.",
    subHelloText2: "Kwalifikowani i doświadczeni nauczyciele niemieckiego, elastyczne godziny lekcji, spersonalizowane lekcje na wszystkich poziomach języka od A1 do C2, różnorodne oferty kursów dla wszystkich grup wiekowych, skuteczne metody nauczania dla szybkich postępów, przygotowanie do egzaminu Goethe-Zertifikat, wsparcie ukierunkowane na rozmowy kwalifikacyjne, nacisk na umiejętności pisania i gramatykę, akcent na skoncentrowaną konwersację i poprawną wymowę, wygodne kursy językowe online z domu, wszechstronne materiały dydaktyczne dla maksymalizacji sukcesu w nauce",
    subHelloText3: "Rozpocznij dziś! Skontaktuj się z nami, aby dowiedzieć się więcej o naszych kursach i usługach i zapisz się, aby podnieść swoje umiejętności języka niemieckiego na wyższy poziom. Czekamy na ciebie!",
    angeboteHeader: "Oferty",
    angeboteTitle: "Co jest oferowane",
    angeboteText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eget egestas",
    allgDeutsch: "Niemiecki ogólny",
    berufDeutsch: "Niemiecki zawodowy",
    intensivDeutsch: "Intensywny kurs niemieckiego",
    testDeutsch: "Przygotowanie do egzaminu językowego 'Goethe Institut'",
    interviewDeutsch: "Przygotowanie do rozmowy kwalifikacyjnej lub egzaminu",
    boosterDeutsch: "Odświeżenie niemieckiego",
    konversationDeutsch: "Niemiecki konwersacyjny",
    grammatikDeutsch: "Gramatyka niemiecka",
    hahilfeDeutsch: "Pomoc w odrabianiu prac domowych i przygotowanie do dyktand lub egzaminów szkolnych: niemiecki, matematyka, angielski itp.",
    foerderungDeutsch: "Korepetycje i wsparcie dla szkół podstawowych i średnich lub uniwersytetów: także dla studentów z dysleksją, dyskalkulią, AD(H)S itp.",
    
    angeboteInfo: "Na życzenie można także zarezerwować kursy językowe w języku angielskim lub francuskim.",
    sprachPakete: "Pakiety kursów językowych",
    stundenPakete: "5, 10, 15, 20 lub 30 godzin",
    beschreibungPakete: "Wszystkie kursy językowe odbywają się online za pomocą Zoom, Skype, Microsoft Teams lub Google Meet. Ceny na życzenie.",
    kontaktFooterTitle: "Skontaktuj się z nami",
    kontaktFooterText: "Tekst Tekst Tekst Tekst",
    kontaktFooterColumnTitle: "Kontakt",
    kontaktFooterColumnPhone: "Telefon",

    About: "O nas",
    Courses: "Kursy",
    Contact: "Kontakt",
    callNow: "Zadzwoń",
    gruppenPakete: "Istnieje możliwość rezerwacji kursów indywidualnych i grupowych!",


};