import React, { useState } from 'react';

const LanguagePopup = ({ onLanguageSelect }) => {
    const [selectedLanguage, setSelectedLanguage] = useState('en'); // Set default to "en"

    const handleLanguageSelect = () => {
        onLanguageSelect(selectedLanguage);
        localStorage.setItem('selectedLanguage', selectedLanguage); // Save the selected language to localStorage
    };


    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-10 rounded-md shadow-md">
                <h2 className="text-xl font-semibold mb-4">Select your language:</h2>
                <select
                    className="border rounded p-2 w-full"
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    value={selectedLanguage}
                >
                    <option value="en">English</option>
                    <option value="de">Deutsch</option>
                    <option value="fr">Français</option>
                    <option value="it">Italiano</option>
                    <option value="pl">Polski</option>
                    <option value="ua">Українська</option>
                    <option value="es">Español</option>
                    <option value="hun">Magyar</option>
                    <option value="bul">Български</option>
                    <option value="cn">中国语</option>
                </select>
                <button
                    className="mt-4 bg-purple-500 hover:bg-purple-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 font-semibold text-white px-4 py-2 rounded-md shadow-sm"
                    onClick={handleLanguageSelect}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default LanguagePopup;
