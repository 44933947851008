export default {
    welcomeMessage: '在线学习语言！德语课程',
    aboutUs: '关于我们',
    contact: '联系方式',
    subWelcome: "欢迎辅助文本 Dolor lomit",
    ctaWelcome: "查看优惠",
    ctaLearnmore: "与我联系",
    introductionHeader: "简介",
    hello: "你好，我叫 Anette",
    subHelloText: "我来自德国，教授德语给儿童、青少年和成年人。我自 2012 年获得了 Goethe C2.2 证书，拥有德国歌德学院的大德国语言文凭，我是教育学硕士，并自 2011 年以来在不同的语言学校教授德语，包括卢森堡的国际或欧洲学校。我的教学重点是面向学校、学术和职业的德语。我的课程从初学者 A1.1 级别到高级 C2.2 级别。我还可以非常好地为您准备工作面试或歌德学院的语言考试。在我们的德语课程中，我们注重口语交流、正确发音、写作和德语语法。我们课堂上使用的材料包括在线或纸质教材、演示文稿、短视频、对话、工作表、歌曲和图片，我们一起描述。我喜欢教学，因为看到我的学生取得进步让我感到非常高兴。预约一节试听课，与我一起学习德语吧！希望很快能见到您！",
    angeboteHeader: "优惠",
    angeboteTitle: "提供什么？",
    angeboteText: "Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas",

    allgDeutsch: "通用德语",
    berufDeutsch: "职业德语",
    intensivDeutsch: "紧急德语课程",
    testDeutsch: "备考“歌德学院”语言考试",
    interviewDeutsch: "面试或考试准备",
    boosterDeutsch: "提高德语水平",
    konversationDeutsch: "德语会话",
    grammatikDeutsch: "德语语法",
    hahilfeDeutsch: "作业辅导和准备笔试或学校考试：德语、数学、英语等。",
    foerderungDeutsch: "初中和高中辅导和支持，也适用于患有阅读障碍、计算障碍、注意力不足（过动症）等学生。",

    angeboteInfo: "根据要求，还可以预订英语或法语课程。",
    sprachPakete: "语言课程套餐",
    stundenPakete: "5、10、15、20 或 30 小时",
    beschreibungPakete: "所有语言课程均在线进行，使用 Zoom、Skype、Microsoft Teams 或 Google Meet。价格根据要求。",
    kontaktFooterTitle: "与我联系",
    kontaktFooterText: "文本 文本 文本 文本",
    kontaktFooterColumnTitle: "联系方式",
    kontaktFooterColumnPhone: "电话",

    About: "关于我",
    Courses: "课程",
    Contact: "联系方式",
    callNow: "立即致电",
    gruppenPakete: "可预订个人和团体课程！",
};
